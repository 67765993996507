export enum MimeType {
    MP4 = 'video/mp4',
    OGG = 'video/ogg',
    WEBM = 'video/webm',
    MPEG = 'video/mpeg',
    JPEG = 'image/jpeg',
    PNG = 'image/png',
    GIF = 'image/gif',
    WEBP = 'image/webp',
    JSON = 'application/json',
    URL_ENCODED = 'application/x-www-form-urlencoded',
    FORM_DATA = 'multipart/form-data',
    ZIP = 'application/zip'
}

export enum GQLMimeType {
    MP4 = 'MP4',
    OGG = 'OGG',
    WEBM = 'WEBM',
    MPEG = 'MPEG',
    JPEG = 'JPEG',
    PNG = 'PNG',
    GIF = 'GIF',
    WEBP = 'WEBP',
    JSON = 'JSON',
    URL_ENCODED = 'URL_ENCODED',
    FORM_DATA = 'FORM_DATA',
    ZIP = 'ZIP'
}

export const MimeTypeValues: { [key in MimeType]: GQLMimeType } = {
    [MimeType.MP4]: GQLMimeType.MP4,
    [MimeType.OGG]: GQLMimeType.OGG,
    [MimeType.WEBM]: GQLMimeType.WEBM,
    [MimeType.MPEG]: GQLMimeType.MPEG,
    [MimeType.JPEG]: GQLMimeType.JPEG,
    [MimeType.PNG]: GQLMimeType.PNG,
    [MimeType.GIF]: GQLMimeType.GIF,
    [MimeType.WEBP]: GQLMimeType.WEBP,
    [MimeType.JSON]: GQLMimeType.JSON,
    [MimeType.URL_ENCODED]: GQLMimeType.URL_ENCODED,
    [MimeType.FORM_DATA]: GQLMimeType.FORM_DATA,
    [MimeType.ZIP]: GQLMimeType.ZIP
};

export function getGQLMimeType(mimeType: MimeType): GQLMimeType {
    return MimeTypeValues[mimeType];
}
export function getMimeType(gqlMimeType: GQLMimeType): MimeType | undefined {
    return (Object.keys(MimeTypeValues) as Array<keyof typeof MimeTypeValues>).find(
        key => MimeTypeValues[key] === gqlMimeType
    );
}

export function getFileEndingFromGQL(gqlMimeType: GQLMimeType): string {
    switch (gqlMimeType) {
        case GQLMimeType.GIF:
            return 'gif';
        case GQLMimeType.JPEG:
            return 'jpg';
        case GQLMimeType.MP4:
            return 'mp4';
        case GQLMimeType.MPEG:
            return 'mpeg';
        case GQLMimeType.OGG:
            return 'ogg';
        case GQLMimeType.PNG:
            return 'png';
        case GQLMimeType.WEBM:
            return 'webm';
        case GQLMimeType.WEBP:
            return 'webp';
        case GQLMimeType.JSON:
            return 'json';
        case GQLMimeType.ZIP:
            return 'zip';
        default:
            return '';
    }
}
export function getFileEnding(gqlMimeType: MimeType): string {
    switch (gqlMimeType) {
        case MimeType.GIF:
            return 'gif';
        case MimeType.JPEG:
            return 'jpg';
        case MimeType.MP4:
            return 'mp4';
        case MimeType.MPEG:
            return 'mpeg';
        case MimeType.OGG:
            return 'ogg';
        case MimeType.PNG:
            return 'png';
        case MimeType.WEBM:
            return 'webm';
        case MimeType.WEBP:
            return 'webp';
        case MimeType.JSON:
            return 'json';
        case MimeType.ZIP:
            return 'zip';
        default:
            return '';
    }
}
